import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import "../../assets/css/Animation.scss";

export default function Navbar() {
  const limitOfSticky = 400;
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > limitOfSticky) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
  }, []);

  return (
    <div className={isSticky ? "navbar__sticky fadeInDown" : "navbar"}>
      <div className="container navbar__space">
        <div className="logo">
          <img
            width="80"
            alt="footer-logo"
            src={process.env.REACT_APP_LOGO_TEXT}
          />
        </div>
        <div className="right-content">
          <h6 className="right-content__title">Portofolio</h6>
          <h6 className="right-content__title">Layanan</h6>
          <h6 className="right-content__title">Tentang Kami</h6>
        </div>
      </div>
    </div>
  );
}
