import React, { useEffect } from "react";
import Home from "./page/Home/Home";

import "./App.css";
import "milligram";

import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 140,
      delay: 100,
      duration: 750,
      easing: "ease",
      once: true,
    });
  }, []);

  return <Home />;
}

export default App;
