import React, { lazy, Suspense } from "react";
import Navbar from "../../components/Navbar/Navbar";
import TopHomeSection from "../../components/TopHomeSection/TopHomeSection";
const HomeMainPage = lazy(() =>
  import("../../components/HomeMainPage/HomeMainPage")
);
const Footer = lazy(() => import("../../components/Footer/Footer"));

export default function Home() {
  const loaderComponent = <h1 className="section-title ">Loading Content...</h1>;
  return (
    <>
      <Navbar />
      <TopHomeSection />
      <Suspense fallback={loaderComponent}>
        <HomeMainPage />
      </Suspense>
      <Suspense fallback={loaderComponent}>
        <Footer />
      </Suspense>
    </>
  );
}
